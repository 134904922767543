.scrollbar {
	scrollbar-width: none;
	-ms-overflow-style: none;
}
.ownerList,
.centerAddress {
	border: 1px solid rgba(181, 172, 172, 0.346);
	border-radius: 10px;
}
.ownerList {
	padding: 1.5rem 1.5rem 1.5rem 1.5rem;
	margin-bottom: 2rem;
}
.centerAddress {
	padding: 1rem;
}

.custom-border {
	border-color: #c49755;
}

.ant-message .ant-message-notice-wrapper .ant-message-custom-content {
	font-family: 'Poppins';
}

.anticon-eye {
	display: none;
}
